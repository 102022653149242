import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"

import Layout from "../components/Layout"
import Contact from "../components/Contact"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  // Extra small devices (portrait phones, less than 576px)
  font-size: 6px;
  width: 80%;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    font-size: 10px;
    width: 500px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 12px;
    width: 600px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    font-size: 14px;
    width: 800px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    font-size: 16px;
    width: 1000px;
  }

  .split {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .left {
    width: 50%;
    /* width: 600px; */

    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
  .right {
    align-items: flex-end;

    width: 50%;
    /* width: 600px; */

    display: flex;
    flex-direction: column;
  }
`

const TextBlock = styled.div`
  font-family: "Monument", sans-serif;
  font-size: 1em;
  /* line-height: 32; */

  li {
    text-decoration: none;
  }
  ul {
    list-style: none;
    margin-top: 0;
  }
`

const TextBlockWGradient = styled(TextBlock)``

const TextBlockWImage = styled(TextBlock)`
  position: relative;
`

const TopLeftTextWGradient = styled(TextBlockWGradient)`
  position: relative;

  text-align: left;

  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1em;

  margin-top: 20%;
  margin-bottom: 10%;

  &:before {
    z-index: -1;
    position: absolute;
    content: "";
    top: 0px;
    right: -5%;
    bottom: 0px;
    left: -20%;

    background: rgb(241, 145, 146);
    background: linear-gradient(
      135deg,
      rgba(241, 145, 146, 1) 0%,
      rgba(242, 201, 121, 1) 100%
    );
  }
`
const TextBlockWImage1 = styled(TextBlockWImage)`
  width: 85%;
  height: auto;

  margin-top: 5%;
  margin-bottom: 10%;

  /* font-size: 12px; */
  position: relative;

  .text-area {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    right: 0.5em;
    bottom: 0.5em;

    text-align: right;
    color: white;
  }
`

const TextBlockWImage2 = styled(TextBlockWImage)`
  width: 95%;
  height: auto;

  margin-bottom: 10%;

  position: relative;

  .text-area {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    right: 0.5em;
    bottom: 0.5em;

    text-align: right;
  }
`

const MiddleRightTextWGradient = styled(TextBlockWGradient)`
  align-self: flex-end;

  position: relative;

  text-align: left;

  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;

  margin-bottom: 20%;

  &:before {
    z-index: -1;
    position: absolute;
    content: "";
    top: 0px;
    right: -20%;
    bottom: 0px;
    left: 0%;

    background: rgb(241, 145, 146);
    background: linear-gradient(
      300deg,
      rgba(241, 145, 146, 1) 0%,
      rgba(242, 201, 121, 1) 100%
    );
  }
`
const BottomLeftTextWGradient = styled(TextBlockWGradient)`
  position: relative;

  text-align: left;

  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1em;

  &:before {
    z-index: -1;
    position: absolute;
    content: "";
    top: 0px;
    right: -5%;
    bottom: 0px;
    left: -20%;

    background: rgb(241, 145, 146);
    background: linear-gradient(
      135deg,
      rgba(241, 145, 146, 1) 0%,
      rgba(242, 201, 121, 1) 100%
    );
  }
`
const TextBlockWImage3 = styled(TextBlockWImage)`
  width: 85%;
  height: auto;

  margin-bottom: 10%;

  .text-area {
    width: 15em;
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    right: 0.5em;
    bottom: 0.5em;

    color: white;
    text-align: right;
  }
`

const BottomRightTextWGradient = styled(TextBlockWGradient)`
  align-self: flex-end;

  position: relative;

  text-align: left;

  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;

  margin-bottom: 20%;

  &:before {
    z-index: -1;
    position: absolute;
    content: "";
    top: 0px;
    right: -20%;
    bottom: 0px;
    left: 25%;

    background: rgb(241, 145, 146);
    background: linear-gradient(
      300deg,
      rgba(241, 145, 146, 1) 0%,
      rgba(242, 201, 121, 1) 100%
    );
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  div {
    width: 50%;
  }
`

const ContactWrapper = styled.div`
  margin-top: 3em;

  margin-left: -10%;
  margin-right: -10%;
`

export default ({ data }) => {
  return (
    <Layout title={"DOMINIKA BARSKA"}>
      <Wrapper>
        <Container>
          <TextBlockWGradient>
            Do you need some new, captivating photos for your website & social
            media? Are you ready to show up in your light? <br />
            <br />
            As a brand photographer, I offer photo sessions for sustainable
            business owners & soulful creatives: dancers, yoga teachers, actors,
            therapists, coaches, etc.
          </TextBlockWGradient>
          <div className="split">
            <div className="left">
              <TopLeftTextWGradient>
                I create visual stories that will:
                <ul>
                  <li>- identify your product & service</li>
                  <li>- increase professionalism for your business</li>
                  <li>- make your brand distinctive & memorable</li>
                  <li>
                    - make your customers trust you & fall in love with your
                    products
                  </li>
                </ul>
                <br />
                HOW DO I WORK?
                <br /> My mission is to capture your unique personality & brand
                values and help you attract your dream clients. What moves me
                most is authenticity, emotions, and simplicity. I work with
                natural light and try to avoid poses and prepared situations. My
                photo sessions happen in a friendly, informal atmosphere where
                you can feel comfortable and be 100% yourself.
              </TopLeftTextWGradient>
              <TextBlockWImage2>
                <Img fluid={data.picture2.childImageSharp.fluid}></Img>
                <div className="text-area">
                  I did an amazing magical photoshoot with Barska Photo Stories
                  and she captured my ENERGY, ESSENCE, AND PERSONALITY
                  beautifully! She gave me space to fully express myself and
                  guided me to be more of who I truly am.
                  <br />
                  If you are looking for a photographer to capture your true
                  essence, then Dominika is the one! She is super sweet, an
                  amazing listener, and open-hearted. I had so much fun with her
                  and I can't wait to do this again!
                  <br />
                  <br />
                  Maria Guerrero
                </div>
              </TextBlockWImage2>
              <BottomLeftTextWGradient>
                As a documentary photographer, I take pictures during personal
                growth retreats & different kinds of artistic workshops
                (dance/yoga/theatre classes, etc.).
                <br />
                <br />I create visual stories that will:
                <ul>
                  <li>- represent your unique style and way of working</li>
                  <li>- let the world see the amazing service you offer</li>
                  <li>
                    - be a record of the experiences you lived and a wonderful
                    gift for all the people that participated in it
                  </li>
                </ul>
                HOW DO I WORK?
                <br />
                While photographing events, I always try to make sure that I
                don't disturb the process in any way. I document life as it
                happens. I believe that the greatest value lies in showing real
                moments and real emotions.
              </BottomLeftTextWGradient>
            </div>
            <div className="right">
              <TextBlockWImage1>
                <Img fluid={data.picture1.childImageSharp.fluid}></Img>
                <div className="text-area">
                  Working with Dominika was a real pleasure. I was satisfied
                  with the outcome but also the way she understands you as an
                  artist. What is really unique is the process before the
                  photoshoot when she takes time to get to know you. She sees
                  right through your needs and includes them in the pictures. I
                  highly recommend Dominika as a brand photographer.
                  <br />
                  <br />
                  Kasia Nik
                </div>
              </TextBlockWImage1>
              <MiddleRightTextWGradient>
                WHAT YOU GET:
                <ul>
                  <li>
                    - a meeting online via Zoom or in-person during which we get
                    to know each other, brainstorm, and establish goals for the
                    session
                  </li>
                  <li>
                    - a brand questionnaire which helps to identify your style
                    and values
                  </li>
                  <li>
                    - my guidance about the locations and outfits - about 2h
                    photoshoot (up to 3 different stylizations and locations)
                  </li>
                  <li>- photo editing</li>
                  <li>
                    - between 20-30 edited pictures sent to you via
                    wetransfer.com
                  </li>
                </ul>
                <br />
                Price: 150 euros
              </MiddleRightTextWGradient>
              <TextBlockWImage3>
                <Img fluid={data.picture3.childImageSharp.fluid}></Img>
                <div className="text-area">
                  It was an amazing time with Dominika Barska. She can feel the
                  people and she knows how to make a good foto. She can discover
                  the character of the person. Work with her is a lovely
                  journey. I want more.
                  <br />
                  <br />
                  Anna Gawłowska
                </div>
              </TextBlockWImage3>
              <BottomRightTextWGradient>
                <div>
                  The duration and price of the photo session will depend on
                  your individual needs.
                </div>
              </BottomRightTextWGradient>
            </div>
          </div>
          <ContactWrapper>
            <Contact />
          </ContactWrapper>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    picture1: file(relativePath: { eq: "offer_page/1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    picture2: file(relativePath: { eq: "offer_page/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    picture3: file(relativePath: { eq: "offer_page/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
