import React from "react"

import styled from "styled-components"

const WrapperForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  width: 100%;
`

const FormRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  padding-bottom: 1em;
`

const FormText = styled.label`
  /* margin-left: 10%; */
  font-family: monument, sans-serif;

  padding-right: 0.5em;

  text-align: right;
  width: 20%;
  box-sizing: border-box;
`

const FromEmailInput = styled.input`
  width: 30%;
  border: 1px solid gray;
  border-radius: 0px;
  height: 2em;
  font-size: 1em;
  border-radius: 0.5em;
`

const FromMessageInput = styled.textarea`
  width: 50%;
  height: 10em;
  resize: none;
  border: 1px solid gray;
  border-radius: 0px;
  font-size: 1em;
  border-radius: 0.5em;
`

const FormButton = styled.button`
  width: 8em;
  height: 4em;

  background-color: white;
  border: 1px solid gray;
  border-radius: 0px;

  font-family: monument, sans-serif;
  font-size: 1em;
`
const BgGradientLeft = styled.div`
  border-top-right-radius: 1em;
  width: calc(20% - 1em);
  margin-right: 1em;
  height: 4em;
  box-sizing: border-box;
  background-color: red;

  background: rgb(241, 145, 146);
  background: linear-gradient(
    135deg,
    rgba(241, 145, 146, 1) 0%,
    rgba(242, 201, 121, 1) 100%
  );
`

const BgGradientRight = styled.div`
  border-top-left-radius: 1em;
  flex-grow: 1;
  height: 4em;
  margin-left: 1em;
  background-color: red;

  background: rgb(241, 145, 146);
  background: linear-gradient(
    300deg,
    rgba(241, 145, 146, 1) 0%,
    rgba(242, 201, 121, 1) 100%
  );
`

function About() {
  return (
    <WrapperForm action="https://formspree.io/f/xvovjkan" method="POST">
      <FormRow>
        <FormText htmlFor="message_input">MESSAGE</FormText>
        <FromMessageInput id="message_input" name="message"></FromMessageInput>
      </FormRow>
      <FormRow>
        <FormText
          className="email"
          htmlFor="email_input"
          style={{ alignSelf: "center" }}
        >
          EMAIL
        </FormText>
        <FromEmailInput id="email_input" type="text" name="_replyto" />
      </FormRow>

      <FormRow>
        <BgGradientLeft />
        <FormButton type="submit">SEND</FormButton>
        <BgGradientRight />
      </FormRow>
    </WrapperForm>
  )
}

export default About
